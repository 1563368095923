<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>Spot Groups</v-card-title>
          <v-card-actions>
            <v-container fluid>
              <v-row justify="space-between" no-gutters>
                <v-col>
                  <v-dialog 
                    v-model="newGroupDialog" 
                    max-width="1000px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        color="primary"
                      >
                        New Group
                      </v-btn>
                    </template>
                    <v-form ref="form" v-model="newFormIsValid">
                      <v-card>
                        <v-card-title class="pb-0">
                          <p class="headline">New Spot Group</p>
                        </v-card-title>
                        <v-card-text class="pb-0">
                          <v-container class="py-0">
                            <v-row align="center" justify="center">
                              <v-col>
                                <v-text-field
                                  v-model="newSpotGroupName"
                                  label="Spot Group Name"
                                  :rules="[rules.required, rules.maxChars(newSpotGroupName, 100)]"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="auto">
                                <v-btn 
                                  @click.prevent="addGroup"
                                  color="success"
                                >
                                  <v-icon>mdi-content-save</v-icon>
                                  Save
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                      </v-card>
                    </v-form>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-data-table
                    :headers="headers"
                    :items="tableItems"
                    :loading="loadingGroups"
                    sort-by="count"
                    :sort-desc="[`count`]"
                    class="elevation-2"
                    disable-pagination
                    hide-default-footer
                    id="spotGroupTable"
                  >
                    <!-- Make group name editable by clicking on it -->
                    <template #item.group_name="props">
                      <v-edit-dialog
                        :return-value.sync="props.item.group_name"
                        @save="saveGroupName(props.item.group_name, props.item.spot_group_id)"
                      > 
                        {{ props.item.group_name }}
                        <template #input>
                          <v-text-field
                            v-model="props.item.group_name"
                            :rules="[rules.required, rules.maxChars(props.item.group_name, 100)]"
                            label="Edit"
                            single-line
                            counter
                          ></v-text-field>
                        </template>
                      </v-edit-dialog>
                    </template>
                    <template #item.delete="{item}">
                      <v-btn color="rgba(255,0,0,.7)" @click.stop="deleteSpotGroup(item)">
                        <v-icon color="white">mdi-delete</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loadingGroups: false,
      newGroupDialog: false,
      newSpotGroupName: null,
      newFormIsValid: true,
      tableItems: [],
      headers: [
        { text: "Spot Group", value: "group_name", divider: true },
        { text: "Spots In Group", value: "count", divider: true, width: "10%" },
        { text: "Delete", value: "delete", divider: true, sortable: false, width: "5%" }
      ],
      rules: {
        maxChars: (value, max) =>
          (value || "").length <= max || "Must be " + max + " or fewer characters",
        required: (value) => !!value || "Required"
      }
    };
  },
  // Lifecycle hook
  created() {
    this.populateGroupSpots();
  },
  methods: {
    populateGroupSpots() {
      this.loadingGroups = true;
      this.$axios
        .get("/medusa/spot/spotGroupsWithCount")
        .then((res) => {
          this.loadingGroups = false;
          this.tableItems = res.data;
        });
    },
    saveGroupName(group_name, spot_group_id) {
      this.$axios
        .put("/medusa/spot/updateSpotGroup", {
          spot_group_id: spot_group_id,
          group_name: group_name
        })
        .then(() => {
          // Display success message
          this.$store.commit("sendAlert", {
            msg: "Spot Group Updated",
            color: "success"
          });
        })
        .catch(() => {
          // Revert groups if there was an error
          this.populateGroupSpots();
        })
    },
    addGroup() {
      this.$axios
        .post("/medusa/spot/insertSpotGroup", {
          group_name: this.newSpotGroupName
        })
        .then(() => {
          // Display success message
          this.$store.commit("sendAlert", {
            msg: "Spot Group Created",
            color: "success"
          });
        })
        .finally(() => {
          this.newGroupDialog = false;
          this.newSpotGroupName = null;
          // Re-populate groups
          this.populateGroupSpots();
        })
    },
    deleteSpotGroup(group) {
      // waits for the confirmation message to be built before continuing
      let confirmationMsg = group.count + " spots are using in this group. Are you sure you want to delete " + group.group_name + "?";
      this.$root
        .$confirm("Delete", confirmationMsg, {
          color: "red",
          width: 400
        })
        .then((confirm) => {
          if (confirm) {
            this.axios
              .delete("/medusa/spot/deleteSpotGroup", {
                data: {
                  spot_group_id: group.spot_group_id
                }
              })
              .then(() => {
                this.populateGroupSpots();
                // Display success alert
                this.$store.commit("sendAlert", {
                  msg: "Spot group deleted",
                  color: "success"
                });
              });
          }
        });
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep #spotGroupTable {
  tbody tr {
    &:nth-of-type(even) {
      background-color: rgba(150, 150, 150, 0.2);
    }
    &:hover {
      background: lighten(gray, 25%);
    }
  }
}
</style>
